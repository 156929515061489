<template>
<div class="c-app flex-row align-items-center bg-login">
    <CContainer>
        <b-row class="justify-content-center">
            <transition appear name="bounce">
                <b-col xl="4" lg="4" md="6" sm="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                    <b-form @submit.stop.prevent="handleSubmit(login)">
                                        <div class="text-center mt-2 mb-4">
                                            <!-- <img src="img/logo_sig_black.png"
                    alt="logo sig" width="200" > -->
                                            <img src="img/logo_sig.png" alt="logo sig" width="300px" class="img-fluid">
                                        </div>
                                        <!-- <b-alert :show="dismissCountDown" dismissible :variant="variant" @dismissed="dismissCountDown = 0" @dismiss-count-down="countDownChanged">{{ message }}</b-alert> -->

                                        <!--<p class="text-muted">Sign In to your account</p>-->
                                        <b-row>
                                            <b-col lg="12">
                                                <validation-provider name="Usuario" :rules="{required: true}" v-slot="validationContext">
                                                    <b-input-group>
                                                        <b-input-group-prepend>
                                                            <b-button disabled variant="light">
                                                                <b-icon icon="person-fill"></b-icon>
                                                            </b-button>
                                                        </b-input-group-prepend>
                                                        <b-form-input size="md" type="text" :state="getValidationState(validationContext)" placeholder="Usuario" v-model="usuario"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                </validation-provider>
                                            </b-col>

                                            <b-col class="mt-3" lg="12">
                                                <validation-provider name="Contraseña" :rules="{required: true}" v-slot="validationContext">
                                                    <b-input-group>
                                                        <b-input-group-prepend>
                                                            <b-button disabled variant="light">
                                                                <b-icon icon="lock-fill"></b-icon>
                                                            </b-button>
                                                        </b-input-group-prepend>
                                                        <b-form-input size="md" type="password" :state="getValidationState(validationContext)" placeholder="Contraseña" v-model="contrasena"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                    </b-input-group>
                                                </validation-provider>
                                            </b-col>

                                        </b-row>
                                        <template v-if="!overlayLogin">
                                            <b-row>
                                                <b-col md="12" class="text-center mt-3">
                                                    <CButton color="dark" type="submit" class="px-4">Iniciar Sesión</CButton>
                                                </b-col>
                                            </b-row>
                                            <!-- <b-row>
                                            <CCol col="12" class="text-center">
                                                <CButton color="link" :to="{name:'Recuperar'}" class="px-0">Olvidaste tu Contraseña?</CButton>
                                            </CCol>
                                        </b-row> -->
                                        </template>
                                        <template v-if="overlayLogin">
                                            <b-overlay show rounded="lg" class="mt-5 mb-5" opacity="0.0">
                                                <template v-slot:overlay>
                                                    <div class="d-flex align-items-center ">
                                                        <b-spinner small type="grow" variant="secondary"></b-spinner>
                                                        <b-spinner type="grow" variant="dark"></b-spinner>
                                                        <b-spinner small type="grow" variant="secondary"></b-spinner>
                                                    </div>
                                                </template>
                                            </b-overlay>
                                        </template>
                                    </b-form>
                                </validation-observer>

                            </CCardBody>
                        </CCard>
                        <!--<CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <h2>Sign up</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <CButton
                  color="light"
                  variant="outline"
                  size="lg"
                >
                  Register Now!
                </CButton>
              </CCardBody>
            </CCard>-->
                    </CCardGroup>
                </b-col>
            </transition>
        </b-row>
    </CContainer>
</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    name: "login",
    data() {
        return {
            usuario: '',
            contrasena: '',
            overlayLogin: false,
            // variant: "danger",
            // message: "",
            // dismissSecs: 3,
            // dismissCountDown: 0
        };
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        // countDownChanged(dismissCountDown) {
        //     this.dismissCountDown = dismissCountDown;
        // },
        login() {
            let me = this;
            me.overlayLogin = true;
            axios
                .post(CONSTANTES.URL_RUTA_SERVICIOS + "auth/login", {
                    usuario: me.usuario.replace(/ /g, ""),
                    password: me.contrasena.replace(/ /g, ""),
                })
                .then(function (response) {
                    // let token = response.data.token;
                    // me.$store.dispatch('setterToken', {
                    //     token
                    // });
                    if (response.data.token) {
                        if (response.data.uidType == '4b450506-0380-4a54-b05a-ba623fe749a4') {
                            localStorage.token = response.data.token;
                            // localStorage.uud_session = me.getCharacters(50) + response.data.user.idTipoUsuario + me.getCharacters(45) + response.data.user.idUsuario + me.getCharacters(42) + response.data.user.idCliente + me.getCharacters(32);
                            me.$router.push({
                                name: "Panel Principal master",
                            });
                        } else {
                            me.swat('error', 'Porfavor acceda desde el login de su empresa'); //Acceso denegado
                            me.overlayLogin = false;
                        }
                    } else {
                        me.swat('error', response.data.message);
                        me.overlayLogin = false;
                    }
                    me.overlayLogin = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal');
                    me.overlayLogin = false;
                });
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }

        // getCharacters(length) {
        //     var result = '';
        //     var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        //     var charactersLength = characters.length;
        //     for (var i = 0; i < length; i++) {
        //         result += characters.charAt(Math.floor(Math.random() * charactersLength));
        //     }
        //     return result;
        // }
    },
 watch:{
    $route (to, from){
       console.log(to,from)
    }
} ,
    mounted() {
        // localStorage.clear();
        // localStorage.usuario = "";
    }
};
</script>

<style scoped>
@-webkit-keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner-border {
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

@keyframes spinner-grow {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.bg-login {
    background-image: url('../../../public/img/background_login.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
